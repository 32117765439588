<template>
  <v-card v-if="control.visible" :class="styles.arrayList.root" elevation="0" class="ma-0 pa-0">
    <v-card-title class="ma-0 pa-0">
      <v-toolbar flat :class="styles.arrayList.toolbar">
        <v-btn icon @click="isCollapsed = !isCollapsed">
          <v-icon>{{ isCollapsed ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
        </v-btn>
        <v-toolbar-title :class="styles.arrayList.label">{{
            computedLabel
          }}</v-toolbar-title>
        <validation-icon
            v-if="control.childErrors.length > 0"
            :errors="control.childErrors"
        />
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on: onTooltip }">
            <v-btn
                fab
                text
                elevation="0"
                small
                :aria-label="`Add to ${control.label}`"
                v-on="onTooltip"
                :class="styles.arrayList.addButton"
                :disabled="
                !control.enabled ||
                (appliedOptions.restrict &&
                  arraySchema !== undefined &&
                  arraySchema.maxItems !== undefined &&
                  dataLength >= arraySchema.maxItems)
              "
                @click="addButtonClick"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          {{ `Add to ${control.label}` }}
        </v-tooltip>
      </v-toolbar>
    </v-card-title>
    <div v-show="!isCollapsed">
      <v-card-text>
        <v-container justify-space-around align-content-center>
          <v-row justify="center">
            <v-simple-table class="array-container flex">
              <thead v-if="control.schema.type === 'object'">
              <tr>
                <th></th>
                <th
                    v-for="(prop, index) in getValidColumnProps(control.schema)"
                    :key="`${control.path}-header-${index}`"
                    scope="col"
                >
                  {{ title(prop) }}
                </th>
                <th
                    v-if="control.enabled"
                    :class="
                    appliedOptions.showSortButtons
                      ? 'fixed-cell'
                      : 'fixed-cell-small'
                  "
                    scope="col"
                ></th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(element, index) in control.data"
                  :key="`${control.path}-${index}`"
                  :class="styles.arrayList.item"
              >
                <td><v-checkbox></v-checkbox></td>
                <td
                    v-for="propName in getValidColumnProps(control.schema)"
                    :key="
                    composePaths(
                      composePaths(control.path, `${index}`),
                      propName
                    )
                  "
                >
                  <dispatch-renderer
                      :schema="control.schema"
                      :uischema="resolveUiSchema(propName)"
                      :path="composePaths(control.path, `${index}`)"
                      :enabled="control.enabled"
                      :renderers="control.renderers"
                      :cells="control.cells"
                  />
                </td>
                <td
                    v-if="control.enabled"
                    :class="
                    appliedOptions.showSortButtons
                      ? 'fixed-cell'
                      : 'fixed-cell-small'
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                          v-on="onTooltip"
                          v-if="appliedOptions.showSortButtons"
                          fab
                          text
                          elevation="0"
                          small
                          aria-label="Move up"
                          :disabled="index <= 0 || !control.enabled"
                          :class="styles.arrayList.itemMoveUp"
                          @click.native="moveUpClick($event, index)"
                      >
                        <v-icon class="notranslate">mdi-arrow-up</v-icon>
                      </v-btn>
                    </template>
                    Move Up
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                          v-on="onTooltip"
                          v-if="appliedOptions.showSortButtons"
                          fab
                          text
                          elevation="0"
                          small
                          aria-label="Move down"
                          :disabled="index >= dataLength - 1 || !control.enabled"
                          :class="styles.arrayList.itemMoveDown"
                          @click.native="moveDownClick($event, index)"
                      >
                        <v-icon class="notranslate">mdi-arrow-down</v-icon>
                      </v-btn>
                    </template>
                    Move Down
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                          v-on="onTooltip"
                          fab
                          text
                          elevation="0"
                          small
                          aria-label="Delete"
                          :class="styles.arrayList.itemDelete"
                          :disabled="
                          !control.enabled ||
                          (appliedOptions.restrict &&
                            arraySchema !== undefined &&
                            arraySchema.minItems !== undefined &&
                            dataLength <= arraySchema.minItems)
                        "
                          @click.native="removeItemsClick($event, [index])"
                      >
                        <v-icon class="notranslate">mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    Delete
                  </v-tooltip>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-row>
        </v-container>
        <v-container v-if="dataLength === 0" :class="styles.arrayList.noData">
          No data
        </v-container>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>



import {
  VCard,
  VCardTitle,
  VCardText,
  VRow,
  VCol,
  VContainer,
  VToolbar,
  VToolbarTitle,
  VTooltip,
  VIcon,
  VBtn,
  VAvatar,
  VSpacer,
  VSimpleTable,
} from 'vuetify/lib';

import Vue, { defineComponent, ref, computed } from 'vue';
import {
  ArrayControlRenderer
} from "@jsonforms/vue2-vuetify";

const ArrayControlRenderCustom = Vue.extend(ArrayControlRenderer).extend({
  components: {
    VRow,
    VSpacer,
    VCardText,
    VTooltip,
    VContainer,
    VSimpleTable,
    VIcon,
    VCard,
    VToolbar,
    VToolbarTitle,
    VCardTitle,
    VBtn
  },
  data: () => ({
    isCollapsed: true
  }),

});

export default ArrayControlRenderCustom;


</script>