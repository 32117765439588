import {httpClient} from "@/plugins/extension";

class IncrementalFieldsService {
    constructor() {
    }

    async updateIncrementalFields(editingItem) {
        let incrementalFields = [];
        editingItem.incrementalFields.forEach((x) => {
            let newJson = {
                "FlowId" : this.flow.id,
                "FieldName" : x.fieldName,
                "FieldValue" : x.currentValue
            }
            incrementalFields.push(newJson);
        });

        //Incremental Fields
        return await this.apiCall(
            `/api/flows/incremental-fields/update-many`,
            "POST",
            incrementalFields,
            {}
        );
    }
}


export default new IncrementalFieldsService();