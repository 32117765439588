var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.control.visible)?_c('v-card',{staticClass:"ma-0 pa-0",class:_vm.styles.arrayList.root,attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"ma-0 pa-0"},[_c('v-toolbar',{class:_vm.styles.arrayList.toolbar,attrs:{"flat":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.isCollapsed = !_vm.isCollapsed}}},[_c('v-icon',[_vm._v(_vm._s(_vm.isCollapsed ? 'mdi-chevron-down' : 'mdi-chevron-up'))])],1),_c('v-toolbar-title',{class:_vm.styles.arrayList.label},[_vm._v(_vm._s(_vm.computedLabel))]),(_vm.control.childErrors.length > 0)?_c('validation-icon',{attrs:{"errors":_vm.control.childErrors}}):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onTooltip }){return [_c('v-btn',_vm._g({class:_vm.styles.arrayList.addButton,attrs:{"fab":"","text":"","elevation":"0","small":"","aria-label":`Add to ${_vm.control.label}`,"disabled":!_vm.control.enabled ||
              (_vm.appliedOptions.restrict &&
                _vm.arraySchema !== undefined &&
                _vm.arraySchema.maxItems !== undefined &&
                _vm.dataLength >= _vm.arraySchema.maxItems)},on:{"click":_vm.addButtonClick}},onTooltip),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,1749877404)},[_vm._v(" "+_vm._s(`Add to ${_vm.control.label}`)+" ")])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCollapsed),expression:"!isCollapsed"}]},[_c('v-card-text',[_c('v-container',{attrs:{"justify-space-around":"","align-content-center":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-simple-table',{staticClass:"array-container flex"},[(_vm.control.schema.type === 'object')?_c('thead',[_c('tr',[_c('th'),_vm._l((_vm.getValidColumnProps(_vm.control.schema)),function(prop,index){return _c('th',{key:`${_vm.control.path}-header-${index}`,attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.title(prop))+" ")])}),(_vm.control.enabled)?_c('th',{class:_vm.appliedOptions.showSortButtons
                    ? 'fixed-cell'
                    : 'fixed-cell-small',attrs:{"scope":"col"}}):_vm._e()],2)]):_vm._e(),_c('tbody',_vm._l((_vm.control.data),function(element,index){return _c('tr',{key:`${_vm.control.path}-${index}`,class:_vm.styles.arrayList.item},[_c('td',[_c('v-checkbox')],1),_vm._l((_vm.getValidColumnProps(_vm.control.schema)),function(propName){return _c('td',{key:_vm.composePaths(
                    _vm.composePaths(_vm.control.path, `${index}`),
                    propName
                  )},[_c('dispatch-renderer',{attrs:{"schema":_vm.control.schema,"uischema":_vm.resolveUiSchema(propName),"path":_vm.composePaths(_vm.control.path, `${index}`),"enabled":_vm.control.enabled,"renderers":_vm.control.renderers,"cells":_vm.control.cells}})],1)}),(_vm.control.enabled)?_c('td',{class:_vm.appliedOptions.showSortButtons
                    ? 'fixed-cell'
                    : 'fixed-cell-small'},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onTooltip }){return [(_vm.appliedOptions.showSortButtons)?_c('v-btn',_vm._g({class:_vm.styles.arrayList.itemMoveUp,attrs:{"fab":"","text":"","elevation":"0","small":"","aria-label":"Move up","disabled":index <= 0 || !_vm.control.enabled},nativeOn:{"click":function($event){return _vm.moveUpClick($event, index)}}},onTooltip),[_c('v-icon',{staticClass:"notranslate"},[_vm._v("mdi-arrow-up")])],1):_vm._e()]}}],null,true)},[_vm._v(" Move Up ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onTooltip }){return [(_vm.appliedOptions.showSortButtons)?_c('v-btn',_vm._g({class:_vm.styles.arrayList.itemMoveDown,attrs:{"fab":"","text":"","elevation":"0","small":"","aria-label":"Move down","disabled":index >= _vm.dataLength - 1 || !_vm.control.enabled},nativeOn:{"click":function($event){return _vm.moveDownClick($event, index)}}},onTooltip),[_c('v-icon',{staticClass:"notranslate"},[_vm._v("mdi-arrow-down")])],1):_vm._e()]}}],null,true)},[_vm._v(" Move Down ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onTooltip }){return [_c('v-btn',_vm._g({class:_vm.styles.arrayList.itemDelete,attrs:{"fab":"","text":"","elevation":"0","small":"","aria-label":"Delete","disabled":!_vm.control.enabled ||
                        (_vm.appliedOptions.restrict &&
                          _vm.arraySchema !== undefined &&
                          _vm.arraySchema.minItems !== undefined &&
                          _vm.dataLength <= _vm.arraySchema.minItems)},nativeOn:{"click":function($event){return _vm.removeItemsClick($event, [index])}}},onTooltip),[_c('v-icon',{staticClass:"notranslate"},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_vm._v(" Delete ")])],1):_vm._e()],2)}),0)])],1)],1),(_vm.dataLength === 0)?_c('v-container',{class:_vm.styles.arrayList.noData},[_vm._v(" No data ")]):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }